import React, { useState } from "react";
import { Switch } from "../../common/Switch";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoachesController } from "../../networking/controllers/coaches-controller";
import { Button } from "../../common/Button";
import { Modal } from "../../common/Modal";
import {
  COACH_ERRORS,
  ADMIN_COACH_TYPES,
  ADMIN_COACH_TYPES_LABEL
} from "../../config/constants";
import toast from "react-hot-toast";
import { useCoachStore } from "../../hooks/useStore";

export const CoachItem = ({ coach, ...rest }) => {
  const queryClient = useQueryClient();
  const currentCoach = useCoachStore(state => state.currentCoach);
  const [hasToSelectAdminCoach, setHasToSelectAdminCoach] = useCoachStore(
    state => [state.hasToSelectAdminCoach, state.setHasToSelectAdminCoach]
  );
  const [selectedCoachToDisable, setSelectedCoachToDisable] = useCoachStore(
    state => [state.selectedCoachToDisable, state.setSelectedCoachToDisable]
  );

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(undefined);

  const hasScopeAdmin = [
    ADMIN_COACH_TYPES.ADMIN,
    ADMIN_COACH_TYPES.SUPER_ADMIN
  ].includes(coach.coachType);

  const adminCoachTypeLabel =
    hasScopeAdmin && ADMIN_COACH_TYPES_LABEL[coach.coachType];

  const switchStatus = {
    active: "left",
    inactive: "right"
  }[coach.status];

  const mutation = useMutation({
    mutationFn: ({ where, data }) => CoachesController.update({ where, data }),
    onMutate: variables => {
      const toastId = toast.loading(variables.toastMessage.mutate);
      return { toastId };
    },
    onSuccess: (_data, variables, context) => {
      toast.success(variables.toastMessage.success, { id: context.toastId });
      queryClient.invalidateQueries({ queryKey: ["coaches"] });
    },
    onError: async (response, variables, context) => {
      const error = await response.json;
      const message = error?.message ?? variables.toastMessage.error;
      toast.error(message, { id: context.toastId });

      if (
        error.message === COACH_ERRORS.NO_ADMIN_COACH_FOUND &&
        currentCoach.coachType === ADMIN_COACH_TYPES.SUPER_ADMIN
      ) {
        setHasToSelectAdminCoach(true);
        setSelectedCoachToDisable({ ...coach, selectedStatus: selectedStatus });
      }

      setSelectedStatus(undefined);
    }
  });

  const onSwitchStatus = async switchSide => {
    const status = {
      left: "active",
      right: "inactive"
    }[switchSide];

    if (status === "inactive") {
      setConfirmationModal(true);
      setSelectedStatus(status);
      return;
    }

    await updateCoachStatus(status);
  };

  const updateCoachStatus = async status => {
    const payload = {
      where: { id: coach.id },
      data: { status: status }
    };

    try {
      await mutation.mutateAsync({
        ...payload,
        toastMessage: {
          mutate: `Updating ${coach.name} Coach`,
          success: `${coach.name} Coach was successfully updated.`,
          error: `Couldn't update ${coach.name} Coach`
        }
      });
    } catch (error) {}
  };

  const onCancelCoachStatus = async () => {
    setSelectedStatus(undefined);
    setConfirmationModal(false);
  };

  const onConfirmCoachStatus = async () => {
    setConfirmationModal(false);
    await updateCoachStatus(selectedStatus);
  };

  const handleOnSelectAdminCoach = async () => {
    try {
      // new selected admin mutation
      await mutation.mutateAsync({
        where: { id: coach.id },
        data: { coach_type: "admin" },
        toastMessage: {
          mutate: `Updating ${coach.name} Coach`,
          success: `Updated ${coach.name} as an admin successfully.`,
          error: `Couldn't update ${coach.name} Coach`
        }
      });

      // disable selected coach
      const status = selectedCoachToDisable.selectedStatus;
      await mutation.mutateAsync({
        where: { id: selectedCoachToDisable.id },
        data: { status: status },
        toastMessage: {
          mutate: `Updating ${selectedCoachToDisable.name} Coach`,
          success: `Successfully updated the status to ${status} for ${selectedCoachToDisable.name}.`,
          errro: `Couldn't update ${selectedCoachToDisable.name} Coach`
        }
      });
      setHasToSelectAdminCoach(false);
      setSelectedCoachToDisable({});
    } catch (error) {}
  };

  return (
    <React.Fragment key={rest.key}>
      <div
        onClick={async () => {
          if (hasToSelectAdminCoach) await handleOnSelectAdminCoach();
        }}
        className={`w-full flex justify-between items-center ${
          hasToSelectAdminCoach
            ? "rounded-lg p-4 transition-colors duration-300 hover:bg-gray-200 cursor-pointer"
            : "py-4"
        }`}
      >
        <p>
          {coach.name}
          {adminCoachTypeLabel && (
            <span className="ml-4 text-complementary-error text-xs">
              {"("}
              {adminCoachTypeLabel}
              {")"}
            </span>
          )}
        </p>
        {!hasToSelectAdminCoach && (
          <Switch
            disable={
              currentCoach.id === coach.id ||
              (hasScopeAdmin &&
                !(currentCoach.coachType === ADMIN_COACH_TYPES.SUPER_ADMIN))
            }
            size="xs"
            initialValue={switchStatus}
            leftText="Active"
            rightText="Inactive"
            getSelectedCallback={onSwitchStatus}
          />
        )}
      </div>
      <Modal isHidden={!confirmationModal}>
        <div className="w-1/3 flex flex-col items-center bg-white p-8 rounded-lg gap-8">
          <p>Are you sure you want to inactivate this coach?</p>
          <div className="flex w-full gap-8">
            <Button
              style="darken"
              text="Discard"
              onClick={onCancelCoachStatus}
            />
            <Button
              style="darken"
              text="Confirm"
              onClick={onConfirmCoachStatus}
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
