import React from "react";
import { Field } from "../../../common/Field";
import { SELECT_PLACEHOLDERS } from "../../../utils/constants";
import {
  ADMIN_COACH_TYPES,
  NON_ADMIN_COACH_TYPES,
  NON_ADMIN_COACH_TYPES_LABEL
} from "../../../config/constants";
import { Select } from "../../../common/Select";
import { capitalizeWords } from "./../../../utils/capitalizeWords";
import { useFranchises } from "../../../franchises/hooks/useFranchises";
import { useCoachStore } from "../../../hooks/useStore";

export const FieldsByStep = ({ step }) => {
  const currentCoach = useCoachStore(state => state.currentCoach);

  const isCurrentCoachSuperAdmin =
    currentCoach.coachType === ADMIN_COACH_TYPES.SUPER_ADMIN;
  const franchises = useFranchises({ doFetch: isCurrentCoachSuperAdmin });

  switch (step) {
    case 1:
      return (
        <>
          <span className="text-xs font-bold">Contact information</span>
          <Field containerStyles="mt-6" name="coachName" label="Full Name" />
          <Field containerStyles="mt-4" name="coachEmail" label="Email" />
          <Select
            containerStyles="mb-6 mt-4"
            name="coachType"
            label="Coach type"
            hideRollbackButton
          >
            <option value={SELECT_PLACEHOLDERS.COACH_TYPE} disabled hidden>
              {SELECT_PLACEHOLDERS.COACH_TYPE}
            </option>
            {Object.entries(NON_ADMIN_COACH_TYPES).map(([key, value]) => (
              <option value={value} key={key}>
                {NON_ADMIN_COACH_TYPES_LABEL[value]}
              </option>
            ))}
          </Select>
          {isCurrentCoachSuperAdmin && (
            <Select
              containerStyles="mb-6 mt-4"
              name="franchiseId"
              label="Franchise"
              hideRollbackButton
            >
              <option value={SELECT_PLACEHOLDERS.FRANCHISE} disabled hidden>
                {SELECT_PLACEHOLDERS.FRANCHISE}
              </option>
              {franchises.map(franchise => (
                <option value={franchise.id} key={franchise.id}>
                  {franchise.name}
                </option>
              ))}
            </Select>
          )}
        </>
      );
    case 2:
      return (
        <div>
          <span className="text-xs font-bold">Coach Login Credentials</span>
          <Field
            containerStyles="mt-4"
            name="userEmailCredential"
            label="User"
            disabled
          />
          <Field
            containerStyles="mt-4"
            name="userPasswordCredential"
            label="Password"
            disabled
          />
        </div>
      );
  }
};
