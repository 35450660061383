import { CoachSerializer } from "./coach.serializer";
import { ProgramSerializer } from "./program-serializer";

export class ClientSerializer {
  static deserialize(client) {
    const lastProgram = client.attributes.last_program.data;

    return {
      id: client.id,
      name: client.attributes.name,
      firstName: client.attributes.first_name,
      lastName: client.attributes.last_name,
      email: client.attributes.email,
      code: client.attributes.code,
      phone: client.attributes.phone,
      timezone: client.attributes.timezone,
      status: client.attributes.status,
      note: client.attributes.note,
      lastProgram: ProgramSerializer.deserialize(lastProgram),
      coach: CoachSerializer.deserializer(client.attributes.coach.data)
    };
  }
}
