import { create } from "zustand";

export const useCoachStore = create(set => ({
  currentCoach: {},
  hasToSelectAdminCoach: false,
  selectedCoachToDisable: {},
  setCurrentCoach: coach => set({ currentCoach: coach }),
  setHasToSelectAdminCoach: boolean => set({ hasToSelectAdminCoach: boolean }),
  setSelectedCoachToDisable: coach => set({ selectedCoachToDisable: coach }),
  resetStore: () =>
    set({
      hasToSelectAdminCoach: false,
      selectedCoachToDisable: {}
    })
}));
