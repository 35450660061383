import { FetchResponse } from "@rails/request.js";
import { api } from "../../config/httpRequest";
import { CoachSerializer } from "../serializers/coach.serializer";

export class CoachesController {
  static async fetchCoaches({ query }) {
    const response = await api({
      method: "GET",
      endpoint: "/coaches",
      query
    });

    const coaches = (await response.json).data;

    return coaches.map(coach => CoachSerializer.deserializer(coach));
  }

  static async fetchCurrentCoach() {
    const response = await api({
      method: "GET",
      endpoint: "/coaches/current"
    });

    const { data } = await response.json;

    return CoachSerializer.deserializer(data);
  }

  static async searchCoach({ throwNotFound, ...query }) {
    try {
      const response = await api({
        method: "GET",
        endpoint: `/coach`,
        query
      });

      const coach = await response.json;

      return await coach;
    } catch (error) {
      const notFoundError =
        error instanceof FetchResponse && error.statusCode === 404;

      if (notFoundError && throwNotFound) return error;
    }
  }

  static async createCoach({ data }) {
    const response = await api({
      method: "POST",
      endpoint: `/coaches`,
      payload: data
    });

    return await response.json;
  }

  static async update({ where, data }) {
    const response = await api({
      method: "PATCH",
      endpoint: `/coaches/${where.id}`,
      payload: { coach: data }
    });

    return await response.json;
  }
}
