import React, { useState } from "react";
import { format, parseISO } from "date-fns";
import { calculateTriageStatusColor } from "../../utils/calculateTriageStatusColor";
import { constants } from "../../config/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProgramsController } from "../../networking/controllers/programs-controller";
import { TriageStatusModal } from "./TriageStatusModal";
import { useNavigate } from "../../hooks/useNavigate";
import { toast } from "react-hot-toast";

export const ClientTableRow = ({ client }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [showTriageModal, setShowTriageModal] = useState(false);

  const updateProgramMutation = useMutation(
    ({ where, data }) => ProgramsController.updateProgram({ where, data }),
    {
      onSuccess: async () => {
        await queryClient.refetchQueries({ queryKey: ["clients"] });
      }
    }
  );

  const updateTriageStatus = async color => {
    if (!client.lastProgram.allowUpdate) return;

    try {
      await toast.promise(
        updateProgramMutation.mutateAsync({
          where: {
            id: "last",
            clientId: client.id
          },
          data: {
            triage_color: color
          }
        }),
        {
          loading: "Updating client",
          success: "Client was successfully updated",
          error: "Couldn't update client"
        }
      );
    } catch (error) {
    } finally {
      setShowTriageModal(false);
    }
  };

  const incrementPhaseStatus = async () => {
    if (
      !client.lastProgram.allowUpdate ||
      client.lastProgram.phase === constants.PROGRAM_MAX_PHASE
    )
      return;

    try {
      await toast.promise(
        updateProgramMutation.mutateAsync({
          where: {
            id: "last",
            clientId: client.id
          },
          data: {
            phase: client.lastProgram.phase + 1
          }
        }),
        {
          loading: "Updating client",
          success: "Client was successfully updated",
          error: "Couldn't update client"
        }
      );
    } catch (error) {}
  };

  const decrementPhaseStatus = async () => {
    if (
      !client.lastProgram.allowUpdate ||
      client.lastProgram.phase === constants.PROGRAM_MIN_PHASE
    )
      return;

    try {
      await toast.promise(
        updateProgramMutation.mutateAsync({
          where: {
            id: "last",
            clientId: client.id
          },
          data: {
            phase: client.lastProgram.phase - 1
          }
        }),
        {
          loading: "Updating client",
          success: "Client was successfully updated",
          error: "Couldn't update client"
        }
      );
    } catch (error) {}
  };

  const handleOnClickTriage = e => {
    if (
      client.lastProgram.allowUpdate &&
      client.lastProgram.status !== "upcoming"
    ) {
      e.stopPropagation();
      setShowTriageModal(true);
    }
  };

  return (
    <>
      <style jsx="true">
        {`
          tr:hover td {
            border-color: white;
          }
        `}
      </style>
      <tr
        className="border-b-0.5 border-t-0.5 h-14 text-neutral-300 hover:bg-hover hover:text-neutral-500 hover:font-medium cursor-pointer"
        onClick={() => navigate(`/clients/${client.id}`)}
      >
        <td className="border-r-0.5 px-4 py-4">
          {client.lastName}, {client.firstName}
        </td>
        <td className="border-r-0.5 px-4 py-4">{client.coach.name}</td>
        <td className="font-medium px-4 py-4 w-214 border-r-0.5">
          {format(parseISO(client.lastProgram.startDate), "MM-dd-yy")}
        </td>
        <td
          className="border-r-0.5 px-4 py-4 relative flex items-center justify-between"
          onClick={e => {
            if (client.lastProgram.allowUpdate) e.stopPropagation();
          }}
        >
          <span>Phase {client.lastProgram.phase}</span>
          <div className="flex flex-col">
            <img
              src="/up-arrow.svg"
              width={15}
              onClick={incrementPhaseStatus}
            />
            <img
              src="/down-arrow.svg"
              width={15}
              onClick={decrementPhaseStatus}
            />
          </div>
        </td>
        <td className="border-r-0.5 px-4 py-4">
          {client.lastProgram.averageWeightLoss.pounds} lbs{" "}
          {client.lastProgram.averageWeightLoss.ounces} oz
        </td>
        <td className="border-r-0.5 px-4 py-4">
          {client.lastProgram.status === "upcoming" ? (
            <div className="mx-10 border border-black"></div>
          ) : (
            <>
              {`${client.lastProgram.daysInRound.numberOfDays} of ${client.lastProgram.daysInRound.totalDays}`}
            </>
          )}
        </td>
        <td className="pl-4 pr-10 relative" onClick={handleOnClickTriage}>
          <div
            style={{
              width: "61px",
              height: "16px",
              borderRadius: "100px"
            }}
            className={`mx-auto my-auto cursor-pointer ${calculateTriageStatusColor(
              client.lastProgram.triageStatus
            )}`}
          >
            {!client.lastProgram.triageStatus.isAuto && (
              <div className="absolute top-2 right-0">
                <img src="/logo-inspire.svg" />
              </div>
            )}
          </div>
          <TriageStatusModal
            client={client}
            showModal={showTriageModal}
            onHide={() => setShowTriageModal(false)}
            updateTriageStatus={updateTriageStatus}
          />
        </td>
      </tr>
    </>
  );
};
