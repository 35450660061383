import React from "react";

export const Switch = ({
  disable = false,
  size,
  initialValue: selected,
  leftText,
  rightText,
  getSelectedCallback,
  optionsStyle = "px-4 py-1 rounded-full",
  selectedStyle = disable ? "bg-neutral-300" : "bg-neutral-500 text-white",
  unSelectedStyle = "",
  className = "w-min h-min flex border border-solid border-neutral-50 rounded-full"
}) => {
  const handleOnSelected = () => {
    if (disable) return;

    getSelectedCallback(
      {
        left: "right",
        right: "left"
      }[selected]
    );
  };

  const textSize = {
    xs: "text-xs",
    sm: "text-sm",
    base: "text-base",
    lg: "text-lg",
    xl: "text-xl"
  }[size];

  return (
    <div
      className={`${className} ${textSize} ${disable && "text-neutral-100"}`}
      onClick={handleOnSelected}
    >
      <span
        className={`${optionsStyle} ${
          selected.toLowerCase() === "left" ? selectedStyle : unSelectedStyle
        }`}
      >
        {leftText}
      </span>
      <span
        className={`${optionsStyle} ${
          selected.toLowerCase() === "right" ? selectedStyle : unSelectedStyle
        }`}
      >
        {rightText}
      </span>
    </div>
  );
};
