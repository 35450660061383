const DAYS_TO_UPDATE = 7;
const PROGRAM_MAX_PHASE = 4;
const PROGRAM_MIN_PHASE = 1;
const INITIAL_STEP_WIZARD = 1;

const NEW_CLIENT_WIZARD = {
  INITIAL_STEP: INITIAL_STEP_WIZARD,
  TOTAL_STEPS: 3
};

const NEW_COACH_WIZARD = {
  INITIAL_STEP: INITIAL_STEP_WIZARD,
  TOTAL_STEPS: 2
};

export const PROGRAM_STATUSES = {
  UPCOMING: "upcoming",
  ONGOING: "ongoing",
  FINISHED: "finished"
};

export const ADMIN_COACH_TYPES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin"
};

export const NON_ADMIN_COACH_TYPES = {
  "FRONT DESK": "front_desk",
  DEFAULT: "default"
};

export const ADMIN_COACH_TYPES_LABEL = {
  super_admin: "Super Admin",
  admin: "Admin"
};

export const NON_ADMIN_COACH_TYPES_LABEL = {
  front_desk: "Front Desk",
  default: "Coach"
};

export const PROGRAM_INTERACTION_TYPES_DISPLAY = {
  in_person: "In person",
  remote: "Remote"
};

export const PROGRAM_INTERACTION_TYPES = [
  {
    label: "In person",
    value: "in_person"
  },
  {
    label: "Remote",
    value: "remote"
  }
];

export const constants = {
  DAYS_TO_UPDATE,
  PROGRAM_MAX_PHASE,
  PROGRAM_MIN_PHASE,
  NEW_CLIENT_WIZARD,
  ADMIN_COACH_TYPES,
  NON_ADMIN_COACH_TYPES,
  ADMIN_COACH_TYPES_LABEL,
  NON_ADMIN_COACH_TYPES_LABEL,
  INITIAL_STEP_WIZARD,
  NEW_COACH_WIZARD,
  PROGRAM_INTERACTION_TYPES_DISPLAY
};

export const COACH_ERRORS = {
  NO_ADMIN_COACH_FOUND: "No admin coach found to transfer clients to."
};
