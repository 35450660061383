import React from "react";
import { useCoaches } from "../hooks/queries/useCoach";
import { CoachItem } from "./CoachItem";
import { Button } from "../../common/Button";
import { Modal } from "../../common/Modal";
import { useFranchises } from "../../franchises/hooks/useFranchises";
import { ADMIN_COACH_TYPES } from "../../config/constants";
import { useCoachStore } from "../../hooks/useStore";

export const CoachesModal = ({
  onClose,
  onNewCoach,
  selectedFranchiseId,
  setSelectedFranchiseId
}) => {
  const currentCoach = useCoachStore(state => state.currentCoach);
  const isCurrentCoachSuperAdmin =
    currentCoach.coachType === ADMIN_COACH_TYPES.SUPER_ADMIN;

  const [selectedCoachToDisable, resetStore] = useCoachStore(state => [
    state.selectedCoachToDisable,
    state.resetStore
  ]);
  const franchises = useFranchises({ doFetch: isCurrentCoachSuperAdmin });
  const coaches = useCoaches({
    status: selectedCoachToDisable?.id ? "active" : "",
    franchise_id: selectedFranchiseId,
    exclude: selectedCoachToDisable?.id ?? ""
  });

  const onChangeFranchise = async id => {
    setSelectedFranchiseId(id);
  };

  return (
    <Modal>
      <div className="w-3/5 bg-white p-8 rounded-lg">
        <div className="flex justify-between items-center mb-4">
          {selectedCoachToDisable?.id ? (
            <p>Select a new admin coach</p>
          ) : (
            currentCoach.coachType === ADMIN_COACH_TYPES.SUPER_ADMIN && (
              <select
                className="py-4 block border-b border-gray-200 disabled:opacity-50 disabled:pointer-events-none outline-none"
                onChange={e => {
                  onChangeFranchise(e.target.value);
                }}
                defaultValue={selectedFranchiseId}
              >
                {franchises.map(franchise => (
                  <option key={franchise.id} value={franchise.id}>
                    {franchise.name} coaches
                  </option>
                ))}
              </select>
            )
          )}
          <button
            className="w-10 h-10 bg-neutral-500 rounded-full"
            onClick={onNewCoach}
          >
            <img src="/plus.svg" className="m-auto" />
          </button>
        </div>
        <div className="overflow-auto" style={{ maxHeight: "50vh" }}>
          {coaches.length > 0 ? (
            coaches.map(coach => <CoachItem coach={coach} key={coach.id} />)
          ) : (
            <p className="text-center py-8">
              No active coaches found. Please create a new one by clicking the
              "+" button.
            </p>
          )}
        </div>
        <Button
          otherClases="mt-8"
          type="button"
          style="darken"
          text="Close"
          onClick={() => {
            resetStore();
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};
