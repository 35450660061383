import { useQuery } from "@tanstack/react-query";
import { FranchisesController } from "../../networking/controllers/franchises-controller";

export const useFranchises = ({ doFetch } = { doFetch: true }) => {
  if (!doFetch) return;

  const { data: franchises } = useQuery({
    queryKey: ["franchises"],
    queryFn: () => FranchisesController.fetchFranchises()
  });

  return franchises;
};
