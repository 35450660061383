import React from "react";
import { ClientTableRow } from "./ClientTableRow";
import { useClients } from "../hooks/queries/useClients";

const ClientsTable = ({ clientsQuery }) => {
  const clients = useClients(clientsQuery);

  return (
    <div
      className="w-2/3 relative overflow-y-scroll"
      style={{ height: "calc(100vh - 81px)" }}
    >
      <table className="w-full max-h-full bg-neutral-10 text-xs text-left">
        <thead className="h-14 sticky top-0 bg-secondary-500 text-white z-10 border-white">
          <tr>
            <th className="font-medium px-4 py-4 w-214 border-r-0.5">
              Client Name
            </th>
            <th className="font-medium px-4 py-4 w-214 border-r-0.5">
              Health Coach
            </th>
            <th className="font-medium px-4 py-4 w-214 border-r-0.5">
              Start Date
            </th>
            <th className="font-medium px-4 py-4 w-120 border-r-0.5">Phase</th>
            <th className="font-medium px-4 py-4 w-114 border-r-0.5">
              Average Daily Weight Loss
            </th>
            <th className="font-medium px-4 py-4 w-94 border-r-0.5">
              Days in Round
            </th>
            <th className="font-medium px-4 py-4 w-94">Status</th>
          </tr>
        </thead>
        <tbody style={{ overflow: "auto" }}>
          {clients.map(client => (
            <ClientTableRow key={client.id} client={client} />
          ))}
        </tbody>
        {clients.length > 0 ? (
          <tfoot>
            <tr>
              <td className="text-center p-2" colSpan={7}>
                <span className="text-gray-700">{clients.length} Clients</span>
              </td>
            </tr>
          </tfoot>
        ) : (
          <></>
        )}
      </table>
    </div>
  );
};

export default ClientsTable;
