import { useQuery } from "@tanstack/react-query";
import { CoachesController } from "../../../networking/controllers/coaches-controller";

export const useCoaches = (query = { status: "active" }) => {
  const { data: coaches } = useQuery({
    queryKey: ["coaches", query],
    queryFn: () =>
      CoachesController.fetchCoaches({
        query: query
      })
  });

  return coaches;
};
