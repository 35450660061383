import React, { useEffect, useState } from "react";
import { useCurrentCoach } from "../coaches/hooks/queries/useCurrentCoach";
import { App } from "../App";

import NavBar from "../common/NavBar";
import ClientsTable from "../clients/components/ClientsTable";
import SearchClientForm from "../clients/components/SearchClientForm";
import NewClientWizard from "../clients/components/NewClientWizard";
import NewCoachWizard from "../coaches/components/NewCoachWizard";

import { ADMIN_COACH_TYPES } from "../config/constants";
import { FranchisesModal } from "../franchises/components/FranchisesModal";
import { Hamburger } from "../common/Hamburger";
import { CoachesModal } from "../coaches/components/CoachesModal";
import { useQueryClient } from "@tanstack/react-query";
import { useCoachStore } from "../hooks/useStore";

const Dashboard = () => {
  const coach = useCurrentCoach();
  const queryClient = useQueryClient();
  const [clientsQuery, setClientsQuery] = useState("");
  const [showNewClientWizard, setShowNewClientWizard] = useState(false);
  const [showNewCoachWizard, setShowNewCoachWizard] = useState(false);
  const [showFranchisesModal, setShowFranchisesModal] = useState(false);
  const [showCoachesModal, setShowCoachesModal] = useState(false);
  const [selectedFranchiseId, setSelectedFranchiseId] = useState(
    coach.franchise.id
  );
  const setCurrentCoach = useCoachStore(state => state.setCurrentCoach);

  useEffect(() => {
    setCurrentCoach(coach);
  }, [coach]);

  return (
    <>
      <NavBar
        enableHamburger={
          coach.coachType === ADMIN_COACH_TYPES.ADMIN ||
          coach.coachType === ADMIN_COACH_TYPES.SUPER_ADMIN
        }
        subtitle={
          <p className="font-semibold text-xl">
            {coach.franchise.name} Clients
          </p>
        }
        rightRender={() => (
          <>
            <span className="text-sm mr-2">{coach.name}</span>
            <button className="text-xs transition duration-200 ease-in-out px-6 h-8 rounded border border-neutral-500 text-neutral-500 cursor-pointer hover:bg-neutral-300 hover:text-white hover:border-neutral-300">
              <a data-method="delete" href="/logout">
                LOGOUT
              </a>
            </button>
            <button
              className="text-xs transition duration-200 ease-in-out px-6 h-8 rounded bg-neutral-500 text-white cursor-pointer hover:bg-neutral-300"
              onClick={() => setShowNewClientWizard(true)}
            >
              <span>NEW CLIENT</span>
            </button>
          </>
        )}
        hamburgerOptions={closeHamburger => (
          <Hamburger onClose={closeHamburger}>
            <li
              className="block px-4 p-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
              onClick={() => {
                setShowCoachesModal(true);
              }}
            >
              Coaches
            </li>
            <li
              className="block px-4 p-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
              onClick={() => {
                setShowNewClientWizard(true);
              }}
            >
              New client
            </li>
            {coach.coachType === ADMIN_COACH_TYPES.SUPER_ADMIN && (
              <li
                className="block px-4 p-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                onClick={() => {
                  setShowFranchisesModal(true);
                }}
              >
                Franchises
              </li>
            )}
            <li className="block px-4 p-4 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer">
              <a className="w-full block" data-method="delete" href="/logout">
                Logout
              </a>
            </li>
          </Hamburger>
        )}
      />
      <div className="w-full flex justify-beteween bg-neutral-20 font-monts">
        <ClientsTable clientsQuery={clientsQuery} />
        <SearchClientForm setClientsQuery={setClientsQuery} />
        {showNewClientWizard && (
          <NewClientWizard
            setShowWizard={setShowNewClientWizard}
            onClose={() => {
              setShowNewClientWizard(false);
            }}
          />
        )}
        {showNewCoachWizard && (
          <NewCoachWizard
            defaultValues={{ franchiseId: selectedFranchiseId }}
            onClose={() => {
              setShowNewCoachWizard(false);
              setShowCoachesModal(true);
            }}
          />
        )}
        {showFranchisesModal &&
          coach.coachType === ADMIN_COACH_TYPES.SUPER_ADMIN && (
            <FranchisesModal
              onClose={() => {
                setShowFranchisesModal(false);
              }}
            />
          )}
        {showCoachesModal && (
          <CoachesModal
            selectedFranchiseId={selectedFranchiseId}
            setSelectedFranchiseId={setSelectedFranchiseId}
            onNewCoach={() => {
              setShowNewCoachWizard(true);
              setShowCoachesModal(false);
            }}
            onClose={async () => {
              setShowCoachesModal(false);
              setSelectedFranchiseId(coach.franchise.id);
              await Promise.all([
                queryClient.refetchQueries({ queryKey: ["coaches"] }),
                queryClient.refetchQueries({ queryKey: ["clients"] })
              ]);
            }}
          />
        )}
      </div>
    </>
  );
};

export default function () {
  return (
    <App>
      <Dashboard />
    </App>
  );
}
