import React, { useState } from "react";
import { Switch } from "../../common/Switch";
import { useMutation } from "@tanstack/react-query";
import { FranchisesController } from "../../networking/controllers/franchises-controller";
import toast from "react-hot-toast";
import { Button } from "../../common/Button";
import { Modal } from "../../common/Modal";

export const FranchiseItem = ({ franchise, ...rest }) => {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [status, setStatus] = useState({
    current: franchise.status,
    selected: undefined
  });

  const switchStatus = {
    active: "left",
    inactive: "right"
  }[status.current];

  const mutation = useMutation({
    mutationFn: ({ where, data }) =>
      FranchisesController.update({ where, data }),
    onError: error => {
      setStatus({
        current: franchise.status,
        selected: undefined
      });
    }
  });

  const onSwitchStatus = async switchSide => {
    const status = {
      left: "active",
      right: "inactive"
    }[switchSide];

    if (status === "inactive") {
      setConfirmationModal(true);
      setStatus(prev => ({ ...prev, selected: status }));
      return;
    }

    await updateFranchiseStatus(status);
  };

  const updateFranchiseStatus = async status => {
    const payload = {
      where: { id: franchise.id },
      data: { status: status }
    };

    try {
      await toast.promise(mutation.mutateAsync(payload), {
        loading: `Updating ${franchise.name} Franchise`,
        success: `${franchise.name} Franchise was successfully updated`,
        error: `Couldn't update ${franchise.name} Franchise`
      });
      setStatus({ current: status, selected: undefined });
    } catch (error) {}
  };

  const onCancelFranchiseStatus = async () => {
    setStatus(prev => ({ ...prev, selected: undefined }));
    setConfirmationModal(false);
  };

  const onConfirmFranchiseStatus = async () => {
    await updateFranchiseStatus(status.selected);
    setConfirmationModal(false);
  };

  return (
    <div key={rest.key}>
      <div className="w-full py-4 flex justify-between items-center">
        <p>{franchise.name}</p>
        <Switch
          size="xs"
          initialValue={switchStatus}
          leftText="Active"
          rightText="Inactive"
          getSelectedCallback={onSwitchStatus}
        />
      </div>
      <Modal isHidden={!confirmationModal}>
        <div className="w-1/3 flex flex-col items-center bg-white p-8 rounded-lg gap-8">
          <p>
            Are you sure you want to inactivate this franchise? This action will
            also deactivate its coaches.
          </p>
          <div className="flex w-full gap-8">
            <Button
              style="darken"
              text="Discard"
              onClick={onCancelFranchiseStatus}
            />
            <Button
              style="darken"
              text="Confirm"
              onClick={onConfirmFranchiseStatus}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
