import React, { useState } from "react";
import { Form } from "../Form";
import { StepCounter } from "./StepCounter";
import { constants } from "../../config/constants";

export const Wizard = ({
  onClose,
  totalSteps,
  schemaByStep,
  initialValues,
  onSubmit,
  onNext,
  children,
  title
}) => {
  const [currentStep, setCurrentStep] = useState(constants.INITIAL_STEP_WIZARD);

  const callbackOnNext = () => setCurrentStep(prev => prev + 1);

  return (
    <>
      <div
        className="h-screen w-screen fixed z-30 top-0 left-0 bottom-0 right-0 flex justify-end"
        style={{ background: "rgb(0 0 0 / 50%)" }}
      >
        <div
          className="relative w-1/3 min-w-96 h-full bg-white flex items-center justify-center"
          style={{
            boxShadow: "-10px 0px 10px rgba(0, 0, 0, 0.1)"
          }}
        >
          <div className="h-full w-full pb-20 px-16 flex flex-col justify-start items-center overflow-y-auto">
            <h2 className="text-xl pt-10 pb-6 font-semibold">{title}</h2>
            <div className="w-32 h-px flex justify-between items-center mb-10">
              {totalSteps > 2 && (
                <StepCounter
                  currentStep={currentStep}
                  totalSteps={totalSteps}
                />
              )}
            </div>
            <Form
              schema={schemaByStep[currentStep]}
              initialValues={initialValues}
              onSubmit={onSubmit}
              onSubmitButton={({ formMethods }) => {
                return (
                  <button
                    className="w-full transition duration-200 ease-in-out px-6 h-10 my-8 rounded bg-primary-300 text-white cursor-pointer hover:bg-primary-800"
                    type={currentStep >= totalSteps ? "submit" : "button"}
                    onClick={() => {
                      if (currentStep >= totalSteps) return;
                      onNext(formMethods, currentStep, callbackOnNext);
                    }}
                  >
                    {currentStep === totalSteps ? "SAVE" : "NEXT"}
                  </button>
                );
              }}
              onCancelButton={({ formMethods }) => (
                <button
                  type="button"
                  className="mr-4 w-full transition duration-200 ease-in-out px-6 h-10 rounded border border-primary-300 text-primary-300 cursor-pointer hover:bg-primary-800 hover:text-white hover:border-primary-800"
                  onClick={() => {
                    formMethods.reset();
                    onClose();
                    setCurrentStep(constants.INITIAL_STEP_WIZARD);
                  }}
                >
                  DISCARD
                </button>
              )}
              buttonsContainer={({ children }) => (
                <div
                  className="absolute w-full flex justify-around items-center mt-4 bottom-0 left-0 px-16 bg-white"
                  style={{ boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)" }}
                >
                  {children}
                </div>
              )}
            >
              <div className="w-full pb-10">{children(currentStep)}</div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
